import Vue from "vue";

// A modern alternative to CSS resets
// https://github.com/necolas/normalize.css
import "normalize.css/normalize.css";
import "./styles/index.scss";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// 在页面加载时读取sessionStorage里的状态信息
if (sessionStorage.getItem("store")) {
  store.replaceState(
    Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("store")))
  );
}

import "./icons";
import "./utils/permission";

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import * as filters from "@/filters";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// import vant
import "@/components/Vant";

// register common components globally
import "@/components/common";

import "@/assets/styles/tailwind.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
