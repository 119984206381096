import { checkBind, wxLogin } from "@/api/telecom";

const state = {
  token: '',
  ccid: '',
  device: '',
  phone: '',
  userInfo: null,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_CCID: (state, ccid) => {
    // setCCID(ccid);
    state.ccid = ccid;
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone;
  },
  SET_DEVICE: (state, device) => {
    // setDevice(device);
    state.device = device;
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = info;
  },
};

const actions = {
  // 通过code换取wx info 和 token
  wxtoken({ commit }, code) {
    return new Promise((resolve, reject) => {
      // const token = "fgiuau234213nklnsaklnd";
      wxLogin(code)
        .then((res) => {
          const data = res.body.data;
          const token = data.token;
          const user = data.user;
          commit("SET_TOKEN", token);
          commit("SET_USER_INFO", user);
          resolve(token);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  // 登录, 获取绑定状态
  login({ commit }, key) {
    return new Promise((resolve, reject) => {
      checkBind(key)
        .then((res) => {
          const data = res?.body?.data;
          // 如果ccid为空，那么有误
          const ccids = data.ccids;
          const device = data.device;
          if (!ccids || ccids.length === 0) {
            reject(new Error("ccid有误或手机没有绑定ccid"));
            return;
          }
          if (data?.phone.length !== 11) {
            // 如果没有绑定手机，那么key必定是设备id
            commit("SET_CCID", ccids[0]);
            commit("SET_DEVICE", device[ccids[0]]);
            // 如果没有绑定手机
            resolve({
              ccids,
              device,
              bind: false,
              phone: null,
            });
          } else {
            commit("SET_PHONE", data.phone);
            resolve({
              ccids,
              device,
              bind: true,
              phone: data.phone,
            });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  // 登出
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("telecom/SET_PAY_INFO", {}, { root: true })
      commit("telecom/SET_BALANCE_INFO", {}, { root: true })
      commit("SET_CCID", "");
      commit("SET_PHONE", "");
      commit("SET_DEVICE", "");
      resolve();
    });
  },

  // 重置token
  resetToken({ commit, dispatch }) {
    return new Promise((resolve) => {
      dispatch("logout");
      commit("SET_TOKEN", "");
      commit("SET_USER_INFO", {});
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
