import NProgress from "nprogress"; // progress bar
import {
  Toast,
  Notify
} from "vant";
import router from "../router";
import {
  NoNeedAppidRoutes,
  NoNeedTokenRoutes,
  NoNeedCcidRoutes,
  NoNeedPhoneRoutes,
  NoNeedPayTypeRoutes,
  getUrlQuery,
} from "./util";
import store from "../store";

router.beforeEach(async (to, from, next) => {
  NProgress.start(); // start progress bar
  // Set Title
  document.title = to.meta.title || "telecom";

  // 先从URL中获取，如果没有就跳到404
  const curAppid = to.query?.appid;
  const oldAppid = store.getters.appid;
  // 设置新的appid
  if (curAppid && curAppid !== oldAppid) {
    store.commit("telecom/SET_APPID", curAppid);
    await store.dispatch("user/resetToken");
    console.log("get appid = ", curAppid);
  }

  // 如果没有appid
  if (!curAppid && !oldAppid) {
    if (NoNeedAppidRoutes.includes(to.name)) {
      next();
      return;
    }
    console.log("no appid redirect to 404");
    next({
      path: "/404",
      query: to.query
    });
    return;
  }

  // 如果没有paytype
  const hasPayType = store.getters.payType;
  console.log("paytype: " + hasPayType);
  if (!hasPayType) {
    if (NoNeedPayTypeRoutes.includes(to.name)) {
      next();
      return;
    }
    // 如果没有paytype从接口获取
    try {
      const done = await store.dispatch("telecom/getAppContext");
      if (done) {
        next();
        return;
      }
    } catch (e) {
      console.error(e);
      console.log("get pay type failed redirect to 404");
      next({
        path: "/404",
        query: to.query
      });
      return;
    }
  }

  // 如果没有token，需要先wx auth
  const hasToken = store.getters.token;
  if (!hasToken) {
    if (NoNeedTokenRoutes.includes(to.name)) {
      console.log("no token go to auth");
      // 在免登录名单中，直接进入
      next();
      return;
    }
    console.log("no token redirect to auth");
    next({
      path: "/wxauth",
      query: to.query
    });
    return;
  }

  if (to.name === "WxAuth") {
    console.log("has token go to login");
    next({
      path: "/login",
      query: to.query
    });
    return;
  }

  // 是否通过链接中的device重新登录
  const newCcid = getUrlQuery("ccid");
  const newDevice = getUrlQuery("device");

  // 如果没有ccid 需要login
  const savedCcid = store.getters.ccid;
  const savedDevice = store.getters.device;

  if (!savedCcid || !savedDevice || (newCcid && newCcid !== savedCcid) || (newDevice && newDevice !== savedDevice)) {
    console.log('to.name = ', to.name);
    if (NoNeedCcidRoutes.includes(to.name)) {
      // 在免登录名单中，直接进入
      next();
      return;
    }

    await store.dispatch("user/logout");
    Notify({
      type: "primary",
      message: "请先登录后再操作"
    });
    console.log('to.query = ', to.query);
    next({
      path: "/login",
      query: to.query
    });
    return;
  }

  // 如果token和ccid都有
  if (to.name === "Login") {
    next("/");
    return;
  }

  // 不需要绑定手机号就能访问
  if (NoNeedPhoneRoutes.includes(to.name)) {
    next();
    return;
  }

  // 否则要判断是否绑定手机号
  const phonePass = !store.getters.needBindPhone || store.getters.phone;
  if (phonePass) {
    next();
    return;
  }

  try {
    const res = await store.dispatch("user/login", savedCcid);
    if (res.bind) {
      next();
      return;
    }

    console.log("go to bind phone");
    next("/bindphone/change?bind=true");
  } catch (e) {
    store.dispatch("user/logout");
    next({
      path: "/login",
      query: to.query
    });
  }
});

router.afterEach(() => {
  Toast.clear();
  NProgress.done(); // finish progress bar
});
