// 黑名单路由，需要用户登录后才能访问
// export const blackList = ["/user"];

// 不需要appid
export const NoNeedAppidRoutes = ["404"];
// 不需要pay type
export const NoNeedPayTypeRoutes = ["WxAuth", "Login", "404"];
// 不需要token
export const NoNeedTokenRoutes = ["WxAuth", "404"];
// 白名单路由，不需要登录就可以访问
export const NoNeedCcidRoutes = ["Login", "404"];
// 不需要绑定手机号就能访问
export const NoNeedPhoneRoutes = ["BindPhoneChange", "404"];

export function getUrlQuery(query) {
  if (!query) return undefined;
  const url = decodeURIComponent(window.location.href);
  const regStr = `[&?]${query}=([^&%#]+)`;
  const regObj = new RegExp(regStr, "g");

  const matchArr = url.match(regObj);
  let matchStr =
    matchArr === null || matchArr.length === 0
      ? undefined
      : matchArr[matchArr.length - 1];
  if (matchStr) {
    const valueArr = matchStr.match(new RegExp(regStr));
    matchStr = valueArr === null ? undefined : valueArr[1];
  }
  return matchStr;
}
