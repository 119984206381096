import axios from "axios";
import { Toast, Dialog } from "vant";
import store from "@/store";
// import { getToken } from "@/utils/auth";
import router from "@/router";

const config = require("./config");
const baseURL = config[process.env.NODE_ENV].baseUrl;

const service = axios.create({
  baseURL,
  // withCredentials: true,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    config.headers["x-tenant-id"] = "5";
    config.headers["x-app-id"] = store.getters.appid;
    // config.headers.Authorization = `Bearer ${getToken()}`;
    config.headers.token = store.getters.token;

    // get ccid
    const ccid = store.getters.ccid;
    config.params = { ...config.params, ccid };
    if (config.method === "post") {
      config.data = { ...(config.data || {}), ccid };
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (!res.success) {
      Toast(res.msg);
      if (res.errorCode === "90025" || res.errorCode === "90400") {
        Dialog.alert({
          title: "提示",
          message: "您还未登录或登录已过期，请重新登录",
        }).then(() => {
          // 筛选token流程
          // store.dispatch("user/resetToken").then(() => {
          //   location.reload();
          // });
          // 直接退出流程
          store.dispatch("user/resetToken").then(() => {
            router.replace("/wxauth");
          });
        });
      }
      return Promise.reject(new Error(res.msg ?? "网络错误，请稍后再试"));
    } else {
      return res;
    }
  },
  (e) => {
    console.log(e);
    Toast.fail({
      message: e.message || "服务繁忙，请稍后再试",
      duration: 3000,
    });
    return Promise.reject(e);
  }
);

export default service;
