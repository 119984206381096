const getters = {
  appid: (state) => state.telecom.appid,
  payType: (state) => state.telecom.payType,
  rechargeValues: (state) => state.telecom.rechargeValues,
  needBindPhone: (state) => state.telecom.needBindPhone,
  balance: (state) => state.telecom.balance,
  setPassword: (state) => state.telecom.setPassword,
  token: (state) => state.user.token,
  ccid: (state) => state.user.ccid,
  device: (state) => state.user.device,
  phone: (state) => state.user.phone,
  userInfo: (state) => state.user.userInfo,
  cardInfo: (state) => state.telecom.cardInfo, // cardCompany (dianXin, lianTong)
  realname: (state) => state.telecom.realname,
  validateUrl: (state) => state.telecom.validateUrl,
};

export default getters;
