module.exports = {
  local: {
    baseUrl: "/dev-api",
    mockUrl: "http://127.0.0.1:7001",
  },
  development: {
    baseUrl: "http://dianxin.wulianka.info/admin/api",
    // baseUrl: "http://sim.video-box.cn/admin/api",
    mockUrl: "https://api.xwhx.top",
  },
  production: {
    baseUrl: "http://dianxin.wulianka.info/admin/api",
    // baseUrl: "http://sim.video-box.cn/admin/api",
    mockUrl: "https://api.xwhx.top",
  },
};
