<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="key" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="key" />

    <tab-bar v-if="$route.meta.showTab" />
  </div>
</template>

<script>
import TabBar from "./components/Tabbar";

export default {
  components: {
    TabBar,
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  created() {
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>
